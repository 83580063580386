import React from 'react';
import {Card} from 'react-bootstrap'
import axios from 'axios'

//This Component is a child Component of Customers Component
class Customer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  //Function which is called when the component loads for the first time
  componentDidMount() {
    this.getCustomerDetails(this.props.val)
  }

  //Function which is called whenver the component is updated
  componentDidUpdate(prevProps) {

    //get Customer Details only if props has changed
    if (this.props.val !== prevProps.val) {
      this.getCustomerDetails(this.props.val)
    }
  }

  //Function to Load the customerdetails data from json.
  getCustomerDetails(id) {
    axios.get('assets/samplejson/customer' + id + '.json').then(response => {
      this.setState({customerDetails: response})
    })
  };

  render() {
    if (!this.state.customerDetails)
      return (<p>Loading Data</p>)
    return (
    <div className="customerdetails">
      <Card>
        <Card.Body>
          <Card.Title componentClass="h3">{this.state.customerDetails.data.name}</Card.Title>
          <Card.Text><p>Name : {this.state.customerDetails.data.name}</p></Card.Text>
          <Card.Text><p>Email : {this.state.customerDetails.data.email}</p></Card.Text>
          <Card.Text><p>Phone : {this.state.customerDetails.data.phone}</p></Card.Text>
          <Card.Text><p>City : {this.state.customerDetails.data.city}</p></Card.Text>
          <Card.Text><p>State : {this.state.customerDetails.data.state}</p></Card.Text>
          <Card.Text><p>Country : {this.state.customerDetails.data.country}</p></Card.Text>
          <Card.Text><p>Organization : {this.state.customerDetails.data.organization}</p></Card.Text>
          <Card.Text><p>Job Profile : {this.state.customerDetails.data.jobProfile}</p></Card.Text>
          <Card.Text><p>Additional Info : {this.state.customerDetails.data.additionalInfo}</p></Card.Text>
        </Card.Body>
      </Card>
    </div>)
  }
}

export default Customer;