import React from "react";
import {Card, Button} from 'react-bootstrap';
import CustomerDetails from './CustomerDetails'
import axios from 'axios'

class Customer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCustomer: 1
    }
  }

  //function which is called the first time the component loads
  componentDidMount() {
    this.getCustomerData();
  }

  //Function to get the Customer Data from json
  getCustomerData() {
    axios.get('assets/samplejson/customerlist.json').then(response => {
      this.setState({customerList: response})
    })
  };
  render() {
    if (!this.state.customerList)
      return (<p>Loading data</p>)
    return (
      <div className="container row">
          <div className="col-md-3">
            {
              this.state.customerList.data.map(customer =>
              <Card>
                <Card.Body>
                  <Card.Title>{customer.name}</Card.Title>
                  <Card.Text><p>{customer.email}</p></Card.Text>
                  <Card.Text><p>{customer.phone}</p></Card.Text>
                  <Button variant="info" onClick={() => this.setState({selectedCustomer: customer.id})}>Click to View Details</Button>
                </Card.Body>
              </Card>)
            }
          </div>
          <div className="col-md-6">
              <CustomerDetails val={this.state.selectedCustomer}/>
          </div>
      </div>
    );
  }

}

export default Customer;